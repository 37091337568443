import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import { isArray, isEmpty, sortBy } from "lodash";
import styled from "styled-components";
import { AppCard } from "components/card";
import { AppSelect } from "components/inputs";
import { AppLineChart } from "components/lineChart";
import { SeriesData } from "components/lineChart/lineChart";
import { AppModal } from "components/modal";
import { Colors } from "helpers/colors";
import {
  amPMSeriesInterface,
  BloodPressureVitals,
  WeightVitals
} from "sharedTypes";
import { RemoteVitalsTypes } from "./remoteVitalsTabs";
import {
  ChartTitleText,
  EmptyDataText,
  ModalContentContainer,
  SymptomDetailChartContainer,
  SymptomDetailListItemHeader,
  SymptomItemDetailKeyText,
  SymptomItemDetailsGridContainer,
  SymptomItemDetailValueText,
  SymptomModalDetailsListContainer,
  SymptomModalDetailsListItem,
  SymptomsTitleText
} from "./styles";
import moment from "moment";
import { DateTimeFormat } from "helpers/dateTimeFormat";
import "./style.css";
import { useReactToPrint } from "react-to-print";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
interface VitalsModalProps {
  readonly isModalOpen: boolean;
  readonly onModalClose: () => void;
  readonly selectedVital: string;
  readonly weightData: [string, number, string][][] | undefined;
  setShowVitalsCharts(type: string): void;
  readonly weightRawData: WeightVitals[];
  readonly bloodPressureRawData: BloodPressureVitals[];
  readonly showVitalsCharts: string;
  readonly bloodPressureChartsData:
    | { data: [string, number, string][][]; series: SeriesData[][] }
    | undefined;
}

export const RemoteVitalsChartsModal: React.FC<VitalsModalProps> = ({
  isModalOpen,
  onModalClose,
  selectedVital,
  weightData,
  setShowVitalsCharts,
  weightRawData,
  showVitalsCharts,
  bloodPressureChartsData,
  bloodPressureRawData
}) => {
  const [selectedSymptomItem, setSelectedSymptomItem] = useState<string>("");
  const patientContext = useContext<PatientContextState>(PatientContext);

  let vitalSignsAmPMSeriesData: SeriesData[][];

  const EmptyModalOKFunc = () => null;

  const onSelectedSymptomItemChange = (value: string) =>
    setShowVitalsCharts(value);

  useEffect(() => {
    setSelectedSymptomItem(selectedVital);
  }, [selectedVital]);

  const calculatePercentile = (data: number[], percentile: number) => {
    const sorted = data.sort((a: number, b: number) => a - b);
    if (sorted.length === 0) return 0;
    const pos = (sorted.length - 1) * percentile;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return Math.round(
        sorted[base] + rest * (sorted[base + 1] - sorted[base])
      );
    } else {
      return Math.round(sorted[base]);
    }
  };

  const isNoData = useMemo(() => {
    if (showVitalsCharts === RemoteVitalsTypes.BloodPressure) {
      if (bloodPressureChartsData) {
        if (
          !isEmpty(bloodPressureChartsData.data) ||
          !isEmpty(
            bloodPressureChartsData.series.reduce((acc: any[], seriesList) => {
              const seriesData = seriesList.reduce((acc: any[], seriesItem) => {
                return acc.concat(seriesItem.data);
              }, []);
              return acc.concat(seriesData);
            }, [])
          )
        ) {
          return false;
        }
      }
      return true;
    }
    if (showVitalsCharts === RemoteVitalsTypes.Weight) {
      if (!isEmpty(weightData?.reduce((acc, item) => acc.concat(item)))) {
        return false;
      }
      return true;
    }
    return true;
  }, [bloodPressureChartsData, weightData, showVitalsCharts]);

  let AMSysEightySevenPercentile: number;
  let AMSysTwelvePointFivePercentile: number;
  let AMDiasEightySevenPercentile: number;
  let AMDiasTwelvePointFivePercentile: number;
  let PMSysEightySevenPercentile: number;
  let PMSysTwelvePointFivePercentile: number;
  let PMDiasEightySevenPercentile: number;
  let PMDiasTwelvePointFivePercentile: number;

  if (bloodPressureChartsData) {
    AMSysEightySevenPercentile = calculatePercentile(
      bloodPressureChartsData.series[0][0].data.map(item => item[1]),
      0.87
    );
    AMSysTwelvePointFivePercentile = calculatePercentile(
      bloodPressureChartsData.series[0][0].data.map(item => item[1]),
      0.125
    );
    AMDiasEightySevenPercentile = calculatePercentile(
      bloodPressureChartsData.series[0][1].data.map(item => item[1]),
      0.87
    );
    AMDiasTwelvePointFivePercentile = calculatePercentile(
      bloodPressureChartsData.series[0][1].data.map(item => item[1]),
      0.125
    );
    PMSysEightySevenPercentile = calculatePercentile(
      bloodPressureChartsData.series[1][0].data.map(item => item[1]),
      0.87
    );
    PMSysTwelvePointFivePercentile = calculatePercentile(
      bloodPressureChartsData.series[1][0].data.map(item => item[1]),
      0.125
    );
    PMDiasEightySevenPercentile = calculatePercentile(
      bloodPressureChartsData.series[1][1].data.map(item => item[1]),
      0.87
    );
    PMDiasTwelvePointFivePercentile = calculatePercentile(
      bloodPressureChartsData.series[1][1].data.map(item => item[1]),
      0.125
    );
  }

  const bloodPressureRawDataWithReportedOn = useMemo(
    () =>
      bloodPressureRawData.map(item => {
        if (item.reported_on) return item;
        return { ...item, reported_on: item.bp_taken_on };
      }),
    [bloodPressureRawData]
  );

  const weightRawDataWithReportedOn = useMemo(
    () =>
      weightRawData.map(item => {
        if (item.reported_on) return item;
        return { ...item, reported_on: item.weight_taken_on };
      }),
    [weightRawData]
  );

  const convertUTCDateToLocalModal = (dateString: any) => {
    const localDateTime = moment.utc(dateString).local();
    const formattedDateTime = localDateTime.format("MMM, DD YYYY hh:mm A");
    return formattedDateTime;
  };

  const convertDateToLocal = (value: string | Date, format?: string) => {
    return moment.utc(value, format).local();
  };

  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });
  const chartRef = useRef<HTMLDivElement>(null);
  const handlePrintChart = useReactToPrint({
    contentRef: chartRef
  });

  return (
    <AppModal
      footer={false}
      cancelText=""
      okText=""
      onCancel={onModalClose}
      onOk={EmptyModalOKFunc}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginRight: 20
          }}
        >
          <ChartTitleText>Remote Vitals</ChartTitleText>
          <AppSelect
            label="Symptoms"
            onChange={onSelectedSymptomItemChange}
            options={[
              { label: "Blood Pressure", value: "Blood Pressure" },
              { label: "Weight", value: "Weight" }
            ]}
            value={selectedSymptomItem}
            style={{
              width: 200,
              marginTop: -10,
              fontFamily: "Century Gothic"
            }}
          />
        </div>
      }
      visible={isModalOpen}
      width="72vw"
    >
      {isNoData ? (
        <EmptyDataText style={{ height: "70vh" }}>
          <div>No Data</div>
        </EmptyDataText>
      ) : (
        <ModalContentContainer>
          <>
            <SymptomDetailChartContainer
              ref={chartRef}
              style={{ position: "relative", paddingTop: 50, paddingLeft: 20 }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "40px",
                  position: "fixed",
                  zIndex: 999,
                  paddingLeft: 20
                }}
              >
                <span className="print-only-name">
                  <b>Patient Name: </b>
                  {patientContext?.patientData?.name}
                </span>

                <Button
                  style={{ borderRadius: 8 }}
                  type="primary"
                  onClick={() => handlePrintChart()}
                  className="print-only"
                >
                  Print Chart
                </Button>
              </div>
              {bloodPressureChartsData &&
                bloodPressureChartsData.series &&
                bloodPressureChartsData.series.map((items, index) => {
                  if (items[0]) {
                    if (items[0].name === "AM Sys") {
                      const tempData = JSON.parse(
                        JSON.stringify(bloodPressureChartsData.series[0])
                      );

                      tempData.forEach((item: amPMSeriesInterface) => {
                        item.name = item.name.replace("AM ", "");
                      });

                      const sysData = tempData.filter(
                        (item: amPMSeriesInterface) => item.name === "Sys"
                      );

                      const sysValues = sysData[0].data.map(
                        (item: [string | number, number]) => item[1]
                      );

                      let maxYAxis = 240;

                      if (isArray(sysValues)) {
                        maxYAxis = Math.round(Math.max(...sysValues) / 10) * 10;
                      }

                      return (
                        <>
                          <AppCard
                            cardHeight="100%"
                            cardWidth="96%"
                            title={
                              <ChartTitleText>
                                AM Blood Pressure{" "}
                                <PercentileContainers>
                                  <Row
                                    style={{
                                      marginTop: "-10px",
                                      textAlign: "right"
                                    }}
                                  >
                                    <Col span={24}>
                                      Systolic: {AMSysTwelvePointFivePercentile}{" "}
                                      - {AMSysEightySevenPercentile}
                                    </Col>{" "}
                                    <Col span={24}>
                                      Diastolic:{" "}
                                      {AMDiasTwelvePointFivePercentile} -{" "}
                                      {AMDiasEightySevenPercentile}
                                    </Col>
                                  </Row>
                                </PercentileContainers>
                              </ChartTitleText>
                            }
                            key={index}
                          >
                            <AppLineChart
                              chartName={items[0].name}
                              chartHeight="220px"
                              seriesData={tempData}
                              selectedSymptom={selectedSymptomItem}
                              multiLineChart
                              customSeriesTooltip
                              customColors={[Colors.DodgerBlue, Colors.Mantis]}
                              customXAxisDate
                              customXaxisDateFormat="MMM/DD"
                              tooltipOutside
                              maxYAxis={maxYAxis}
                              minYAxis={0}
                              yTickInterval={25}
                              yEndOnTick={true}
                            />
                          </AppCard>
                        </>
                      );
                    }
                    if (items[0].name === "AM Dias") {
                      return null;
                    }
                    if (items[0].name === "PM Sys") {
                      const tempData = JSON.parse(
                        JSON.stringify(bloodPressureChartsData.series[1])
                      );

                      tempData.forEach((item: amPMSeriesInterface) => {
                        item.name = item.name.replace("PM ", "");
                      });

                      const sysData = tempData.filter(
                        (item: amPMSeriesInterface) => item.name === "Sys"
                      );

                      const sysValues = sysData[0].data.map(
                        (item: [string | number, number]) => item[1]
                      );

                      let maxYAxis = 240;

                      if (isArray(sysValues)) {
                        maxYAxis = Math.round(Math.max(...sysValues) / 10) * 10;
                      }

                      return (
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={
                            <ChartTitleText>
                              PM Blood Pressure{" "}
                              <PercentileContainers>
                                <Row
                                  style={{
                                    marginTop: "-10px",
                                    textAlign: "right"
                                  }}
                                >
                                  <Col span={24}>
                                    Systolic: {PMSysTwelvePointFivePercentile} -{" "}
                                    {PMSysEightySevenPercentile}
                                  </Col>{" "}
                                  <Col span={24}>
                                    Diastolic: {PMDiasTwelvePointFivePercentile}{" "}
                                    - {PMDiasEightySevenPercentile}
                                  </Col>
                                </Row>
                              </PercentileContainers>
                            </ChartTitleText>
                          }
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0].name}
                            chartHeight="220px"
                            seriesData={tempData}
                            selectedSymptom={selectedSymptomItem}
                            multiLineChart
                            customSeriesTooltip
                            customColors={[Colors.DodgerBlue, Colors.Mantis]}
                            customXAxisDate
                            customXaxisDateFormat="MMM/DD"
                            tooltipOutside
                            maxYAxis={maxYAxis}
                            minYAxis={0}
                            yTickInterval={25}
                            yEndOnTick={true}
                          />
                        </AppCard>
                      );
                    }
                    if (items[0].name === "PM Dias") {
                      return null;
                    }
                    return null;
                  }
                })}
              {weightData &&
                weightData.map((items, index) => {
                  if (items[0]) {
                    if (items[0][2] === "AM Sys") {
                      return (
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={
                            <ChartTitleText>AM Blood Pressure</ChartTitleText>
                          }
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0][2]}
                            chartHeight="140px"
                            seriesData={vitalSignsAmPMSeriesData[0]}
                            selectedSymptom={selectedSymptomItem}
                            multiLineChart
                            customSeriesTooltip
                            customColors={[Colors.DodgerBlue, Colors.Mantis]}
                            customXAxisDate
                            customXaxisDateFormat="MMM/DD"
                            tooltipOutside
                          />
                        </AppCard>
                      );
                    }
                    if (items[0][2] === "AM Dias") {
                      return null;
                    }
                    if (items[0][2] === "PM Sys") {
                      return (
                        <AppCard
                          cardHeight="100%"
                          cardWidth="96%"
                          title={
                            <ChartTitleText>PM Blood Pressure</ChartTitleText>
                          }
                          key={index}
                        >
                          <AppLineChart
                            chartName={items[0][2]}
                            chartHeight="140px"
                            seriesData={vitalSignsAmPMSeriesData[1]}
                            selectedSymptom={selectedSymptomItem}
                            multiLineChart
                            customSeriesTooltip
                            customColors={[Colors.DodgerBlue, Colors.Mantis]}
                            customXAxisDate
                            customXaxisDateFormat="MMM/DD"
                            tooltipOutside
                          />
                        </AppCard>
                      );
                    }
                    if (items[0][2] === "PM Dias") {
                      return null;
                    }
                    return (
                      <AppCard
                        cardHeight="100%"
                        cardWidth="96%"
                        title={<ChartTitleText>{items[0][2]}</ChartTitleText>}
                        key={index}
                      >
                        <AppLineChart
                          chartName={items[0][2]}
                          chartHeight="140px"
                          customColors={["#05348f"]}
                          data={items}
                          mapperType={items[0][2]}
                          selectedSymptom={selectedSymptomItem}
                          customXAxisDate
                          customXaxisDateFormat="MMM/DD"
                          tooltipOutside
                          minYAxis={0}
                          yTickInterval={50}
                          yEndOnTick={true}
                        />
                      </AppCard>
                    );
                  }
                })}
            </SymptomDetailChartContainer>
          </>
          {/* add print functionality */}

          <SymptomModalDetailsListContainer ref={contentRef}>
            <span
              className="print-only-name"
              style={{ width: "100%", marginTop: 20 }}
            >
              <b>Patient Name: </b>
              {patientContext?.patientData?.name}
            </span>
            <SymptomsTitleText
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span>Details</span>
              <Button
                style={{ borderRadius: 8, marginRight: 12 }}
                type="primary"
                onClick={() => reactToPrintFn()}
                className="print-only"
              >
                Print Chart
              </Button>
            </SymptomsTitleText>
            {showVitalsCharts === RemoteVitalsTypes.BloodPressure && (
              <>
                {sortBy(bloodPressureRawDataWithReportedOn, value =>
                  Date.parse(value.reported_on)
                )
                  .reverse()
                  .map((item, index) => {
                    return (
                      <SymptomModalDetailsListItem
                        key={index}
                        showHr={
                          index !==
                          bloodPressureRawDataWithReportedOn.length - 1
                        }
                      >
                        <SymptomDetailListItemHeader>
                          {item.reported_by
                            ? `${convertUTCDateToLocalModal(
                                item.reported_on
                              )} | By ${item.reported_by}`
                            : "Remote"}
                        </SymptomDetailListItemHeader>
                        {item.am_bp && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`AM BP:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.am_bp}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.pm_bp && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`PM BP:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.pm_bp}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {!item.reported_by && item.bp_taken_on && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`BP taken on:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                <DateTimeFormat
                                  date={convertDateToLocal(
                                    item.bp_taken_on,
                                    "M/D/YYYY H:mm:ss" ||
                                      "M/D/YYYY" ||
                                      "MM-DD-YYYY hh:mm A"
                                  )}
                                  isModalFormat={true}
                                />
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.bp_taken_date && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`BP taken on:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                <DateTimeFormat
                                  date={convertDateToLocal(
                                    item.bp_taken_date,
                                    "M/D/YYYY H:mm:ss" ||
                                      "M/D/YYYY" ||
                                      "MM-DD-YYYY hh:mm A"
                                  )}
                                  isModalFormat={true}
                                />
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.bp_taken_time && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`When:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.bp_taken_time}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.pos && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`Posture:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.pos}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.symptoms && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`BP symptoms or comments:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.symptoms}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.am_systolic_top && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`AM Syst:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.am_systolic_top}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.am_diastolic_bottom && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`AM Dias:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.am_diastolic_bottom}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.pm_systolic_top && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`PM Syst:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.pm_systolic_top}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.pm_diastolic_bottom && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`PM Dias:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.pm_diastolic_bottom}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.hr_taken_date && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`HR taken on:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {convertUTCDateToLocalModal(item.hr_taken_date)}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {item.h_pulse && (
                          <SymptomItemDetailsGridContainer>
                            <React.Fragment>
                              <SymptomItemDetailKeyText>{`HR:`}</SymptomItemDetailKeyText>
                              <SymptomItemDetailValueText>
                                {item.h_pulse}
                              </SymptomItemDetailValueText>
                            </React.Fragment>
                          </SymptomItemDetailsGridContainer>
                        )}
                        {/* <hr
                          className="print-only"
                          style={{
                            backgroundColor: "grey",
                            height: "0.1px",
                            border: "none"
                          }}
                        /> */}
                      </SymptomModalDetailsListItem>
                    );
                  })}
              </>
            )}
            {showVitalsCharts === RemoteVitalsTypes.Weight && (
              <>
                {sortBy(weightRawDataWithReportedOn, value =>
                  Date.parse(value.reported_on)
                ).map((item, index) => (
                  <SymptomModalDetailsListItem
                    key={index}
                    showHr={index !== weightRawData.length - 1}
                  >
                    <SymptomDetailListItemHeader>{`${convertUTCDateToLocalModal(
                      item.reported_on
                    )} | By ${item.reported_by}`}</SymptomDetailListItemHeader>
                    {item.ch_lb && (
                      <SymptomItemDetailsGridContainer>
                        <React.Fragment>
                          <SymptomItemDetailKeyText>{`Amount(Lb):`}</SymptomItemDetailKeyText>
                          <SymptomItemDetailValueText>
                            {item.ch_lb}
                          </SymptomItemDetailValueText>
                        </React.Fragment>
                      </SymptomItemDetailsGridContainer>
                    )}
                    {item.ch_kg && (
                      <SymptomItemDetailsGridContainer>
                        <React.Fragment>
                          <SymptomItemDetailKeyText>{`Amount(Kg):`}</SymptomItemDetailKeyText>
                          <SymptomItemDetailValueText>
                            {item.ch_kg}
                          </SymptomItemDetailValueText>
                        </React.Fragment>
                      </SymptomItemDetailsGridContainer>
                    )}
                    {item.weight_taken_on && (
                      <SymptomItemDetailsGridContainer>
                        <React.Fragment>
                          <SymptomItemDetailKeyText>{`Weight taken on:`}</SymptomItemDetailKeyText>
                          <SymptomItemDetailValueText>
                            {convertUTCDateToLocalModal(item.weight_taken_on)}
                          </SymptomItemDetailValueText>
                        </React.Fragment>
                      </SymptomItemDetailsGridContainer>
                    )}
                  </SymptomModalDetailsListItem>
                ))}
                {/* <hr
                  className="print-only"
                  style={{
                    backgroundColor: "grey",
                    height: "0.1px",
                    border: "none"
                  }}
                /> */}
              </>
            )}
          </SymptomModalDetailsListContainer>
        </ModalContentContainer>
      )}
    </AppModal>
  );
};

const PercentileContainers = styled.span`
  font-size: 13px;
  color: ${Colors.Grey};
  float: right;
`;
