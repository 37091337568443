import {
  MeetingContextState,
  MeetingModalContext,
  sessionStatusValues
} from "contextApis/meetingModalContext";
import React, {
  useEffect,
  useRef,
  useContext,
  useState,
  useCallback
} from "react";
import styled from "styled-components";
import { Device } from "@twilio/voice-sdk";
import { UserRoles } from "constants/roles";
import {
  PatientContext,
  PatientContextState
} from "contextApis/patientContext";
import { useQuery } from "react-query";
import { createApiClient } from "apiClient";
import { Avatar, message, Tooltip, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { callingIcon, disconnectIcon, threeDotIcon } from "./icons";
import { UserContext, UserContextState } from "contextApis/userContext";
import axios from "axios";
import internal from "stream";
import { useParams } from "react-router";
declare module "@twilio/voice-sdk" {}
const codecPreferences: any[] = ["opus", "pcmu"];
const MeetingWithTwilio: React.FC = () => {
  const userRole = sessionStorage.getItem("userRole");
  const device = useRef<Device | null>(null);
  const meetingContext = useContext<MeetingContextState>(MeetingModalContext);
  const patientContext = useContext<PatientContextState>(PatientContext);
  const [twilioToken, setTwilioToken] = useState("");
  const [callStatus, setCallStatus] = useState<string>("calling");
  const [callTime, setCallTime] = useState<number>(0);
  const timerRef = useRef<number | null>(null);
  const userContext = useContext<UserContextState>(UserContext);
  const patientId = sessionStorage.getItem("patientId") || "";
  const groupID = sessionStorage.getItem("group_id") || "";
  const assessed = sessionStorage.getItem("alert_call");

  const startTimer = useCallback(() => {
    timerRef.current = window.setInterval(() => {
      setCallTime(prevTime => prevTime + 1);
    }, 1000);
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  useEffect(() => {
    const fetchCall = async () => {
      try {
        if (twilioToken) {
          console.log("twilioToken", twilioToken);

          device.current = new Device(twilioToken, {
            codecPreferences: []
          });
          const params = {
            To: meetingContext?.pstnPhoneNumber,
            From: meetingContext?.fromPhoneNumber,
            owner_id: String(userContext.userData?.internal_id),
            participant: patientId,
            current_user: userContext?.isProviderModal ? "1" : "0",
            group_id: groupID,
            alert_assist: String(assessed)
          };
          console.log("params", params);

          device.current.register();
          if (device.current) {
            const call = await device.current.connect({ params });
            call.on("accept", async () => {
              setCallStatus("accepted");
              meetingContext.setSessionStatus(sessionStatusValues.STARTED);
              startTimer();
            });

            call.on("ringing", () => {
              setCallStatus("ringing");
              meetingContext.setSessionStatus(sessionStatusValues.STARTED);
            });
            call.on("connected", () => {
              console.log("connected");
              setCallStatus("connected");
            });
            call.on("disconnect", () => {
              setTwilioToken("");
              meetingContext.maximize(false);
              meetingContext.closeSession();
              stopTimer();
            });
            call.on("cancel", () => {
              setTwilioToken("");
              setCallStatus("Disconnected");
            });
          } else {
            message.error("Unable to make call");
            setCallStatus("");
            meetingContext.maximize(false);
            meetingContext.closeSession();
          }
        }
      } catch (error: any) {
        console.log(error?.message);
      }
    };
    fetchCall();
  }, [twilioToken]);

  const handleTwilioToken = async (data: any) => {
    setTwilioToken(data?.token);
  };

  // const { refetch: refechEndMeeting } = useQuery(
  //   "ending-twilio-meeting",
  //   async () => {
  //     return await createApiClient().endTwilioMeeting(
  //       String(userContext.userData?.internal_id)
  //     );
  //   },
  //   {
  //     enabled: false,
  //     cacheTime: 0,
  //     staleTime: 0,
  //     onError: () => {
  //       message.error("There was a problem joining this meeting.");
  //     }
  //   }
  // );

  const { refetch: refechToken } = useQuery(
    "fething-twilio-token",
    async () => {
      return await createApiClient().getTwilioToken();
    },
    {
      enabled: false,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (data: any) => {
        handleTwilioToken(data);
      },
      onError: () => {
        message.error("There was a problem joining this meeting.");
        meetingContext.maximize(false);
        meetingContext.closeSession();
      }
    }
  );

  useEffect(() => {
    if (userRole === UserRoles.PATIENT) {
      if (meetingContext.pstnPhoneNumber) {
        refechToken();
      }
    } else {
      if (patientContext.patientData?.id) {
        refechToken();
      }
    }
  }, [patientContext.patientData]);

  const handleDisconnectCall = () => {
    if (device.current) {
      device.current.disconnectAll();
      setCallStatus("Disconnected");
      setTwilioToken("");
    } else if (callStatus === "calling") {
      setCallStatus("Disconnected");
      meetingContext.maximize(false);
      meetingContext.closeSession();
      setTwilioToken("");
    }
  };

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours.toString().padStart(2, "0")}:${
      mins < 10 ? "0" : ""
    }${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return (
    <Wrapper>
      <div className="container">
        <div className="inner-div">
          <Avatar
            size={80}
            icon={
              callStatus === "calling" || callStatus === "ringing" ? (
                <div dangerouslySetInnerHTML={{ __html: callingIcon }} />
              ) : (
                <UserOutlined />
              )
            }
          />
          <p style={{ color: "black", fontSize: "22px" }}>
            {meetingContext.pstnPhoneNumber}
          </p>
          <div className="callStatusDiv">
            {callStatus === "accepted" ? (
              <p style={{ color: "black", fontSize: "22px" }}>
                {formatTime(callTime)}
              </p>
            ) : (
              (callStatus === "calling" || callStatus === "ringing") && (
                <>
                  <p style={{ color: "black", fontSize: "22px" }}>
                    {callStatus}
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: threeDotIcon }} />
                </>
              )
            )}
          </div>

          <Avatar
            className="zoom-avatar"
            onClick={handleDisconnectCall}
            size={50}
            style={{ backgroundColor: "red" }}
            icon={
              <div
                style={{ marginTop: "5px" }}
                dangerouslySetInnerHTML={{ __html: disconnectIcon }}
              />
            }
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default MeetingWithTwilio;

const Wrapper = styled.div`
  .inner-div {
    width: 100%;
    text-align: center;
  }
  .container {
    width: 100%;
    display: "flex";
    justify-content: center;
    align-items: center;
    height: 65vh;
  }

  .cercle {
    border-radius: "50%";
    border: "1px solid black";
    padding: 10px;
  }
  .callStatusDiv {
    display: flex;
    align-item: center;
    justify-content: center;
  }

  .zoom-avatar {
    transition: transform 0.3s ease-in-out;
  }

  .zoom-avatar:hover {
    transform: scale(1.2);
  }
`;
