import React, { createContext, useState } from "react";
import { VideoTransformOptions } from "components/meetings/types";

export const sessionStatusValues = {
  NOTSTARTED: "NOTSTARTED",
  STARTED: "STARTED",
  ENDED: "ENDED"
};

export type MeetingContextState = {
  readonly targetUser: { name: string; id: string | undefined };
  readonly setTargetUser: (name: string, id?: string | undefined) => void;
  readonly currentPage: number;
  readonly setCurrentPage: (page: number) => void;
  readonly maximize: (max: boolean) => void;
  readonly updateFetchCallRecords: (show: boolean) => void;
  readonly setShowUpdateCallDetailsPopup: (show: boolean) => void;
  readonly setShowAddCallConfirmationModal: (show: boolean) => void;
  readonly setRefreshCallRecordsStatus: (val: boolean) => void;
  readonly isMaximized: boolean;
  readonly fetchCallRecords: boolean;
  readonly showAddCallConfirmationModal: boolean;
  readonly showUpdateCallDetailsPopup: boolean;
  readonly refreshCallRecords: boolean;
  readonly closeSession: () => void;
  readonly fromPhoneNumber: string;
  readonly pstnPhoneNumber: string;
  readonly setPSTNPhoneNumber: (phoneNumber: string) => void;
  readonly setFromPhoneNumber: (phoneNumber: string) => void;
  readonly joinMeetingID: string;
  readonly setJoinMeetingID: (id: string) => void;
  readonly sessionStatus: string;
  readonly setSessionStatus: (status: string) => void;
  readonly callRecordId: string;
  readonly setCallRecordId: (item: string) => void;
  readonly selectedVideoTransformOption: string;
  readonly setSelectedVideoTransformOption: (selectedOption: string) => void;
  readonly deviceSettingsCameraToggle: boolean;
  readonly setDeviceSettingsCameraToggle: (toggle: boolean) => void;
};

const initialValue: MeetingContextState = {
  targetUser: { name: "", id: "" },
  setTargetUser: () => null,
  maximize: () => null,
  updateFetchCallRecords: () => null,
  setShowUpdateCallDetailsPopup: () => null,
  setShowAddCallConfirmationModal: () => null,
  setRefreshCallRecordsStatus: () => null,
  isMaximized: false,
  fetchCallRecords: false,
  showAddCallConfirmationModal: false,
  showUpdateCallDetailsPopup: false,
  refreshCallRecords: false,
  currentPage: 0,
  pstnPhoneNumber: "",
  fromPhoneNumber: "",
  setCurrentPage: () => null,
  closeSession: () => null,
  setPSTNPhoneNumber: () => null,
  setFromPhoneNumber: () => null,
  joinMeetingID: "",
  setJoinMeetingID: () => null,
  sessionStatus: "",
  setSessionStatus: () => null,
  callRecordId: "",
  setCallRecordId: () => null,
  selectedVideoTransformOption: "",
  setSelectedVideoTransformOption: () => null,
  deviceSettingsCameraToggle: false,
  setDeviceSettingsCameraToggle: () => null
};

export const MeetingModalContext =
  createContext<MeetingContextState>(initialValue);
MeetingModalContext.displayName = "MeetingContext";

export const MeetingModalProvider: React.FC = ({ children }) => {
  const [targetUser, setUser] = useState<{
    name: string;
    id: string | undefined;
  }>({
    name: "",
    id: ""
  });
  const [currentPage, setPage] = useState<number>(0);
  const [isMaximized, setIsMaximized] = useState<boolean>(false);
  const [pstnPhoneNumber, setPSTNPhoneNumber] = useState<string>("");
  const [fromPhoneNumber, setFromPhoneNumber] = useState<string>("");
  const [joinMeetingID, setJoinMeetingID] = useState<string>("");
  const [sessionStatus, setSessionStatus] = useState<string>(
    sessionStatusValues.NOTSTARTED
  );
  const [callRecordId, setCallRecordId] = useState<string>("");
  const [selectedVideoTransformOption, setSelectedVideoTransformOption] =
    useState(VideoTransformOptions.None);
  const [deviceSettingsCameraToggle, setDeviceSettingsCameraToggle] =
    useState(false);
  const [fetchCallRecords, setFetchCallRecords] = useState<boolean>(false);
  const [refreshCallRecords, setRefreshCallRecords] = useState<boolean>(false);
  const [showUpdateCallDetailsPopup, setShowUpdateCallDetailsPopup] =
    useState(false);
  const [showAddCallConfirmationModal, setShowAddCallConfirmationModal] =
    useState(false);

  const maximize = (max: boolean) => setIsMaximized(max);

  const updateFetchCallRecords = (show: boolean) => setFetchCallRecords(show);

  const setRefreshCallRecordsStatus = (val: boolean) =>
    setRefreshCallRecords(val);

  const setTargetUser = (name: string, id?: string | undefined) => {
    setUser({
      name: name,
      id: id
    });
  };

  const setCurrentPage = (page: number) => {
    setPage(page);
  };

  const closeSession = () => {
    setCurrentPage(0);
    setTargetUser("", "");
    setIsMaximized(false);
    setPSTNPhoneNumber("");
    // setFromPhoneNumber("");
    setSessionStatus(sessionStatusValues.ENDED);
    setSelectedVideoTransformOption(VideoTransformOptions.None);
    setDeviceSettingsCameraToggle(false);
    setFetchCallRecords(true);
  };

  return (
    <MeetingModalContext.Provider
      value={{
        targetUser,
        setTargetUser,
        currentPage,
        setCurrentPage,
        maximize,
        isMaximized,
        closeSession,
        pstnPhoneNumber,
        setPSTNPhoneNumber,
        setFromPhoneNumber,
        fromPhoneNumber,
        setJoinMeetingID,
        joinMeetingID,
        setSessionStatus,
        sessionStatus,
        setCallRecordId,
        callRecordId,
        setSelectedVideoTransformOption,
        selectedVideoTransformOption,
        setDeviceSettingsCameraToggle,
        deviceSettingsCameraToggle,
        updateFetchCallRecords,
        fetchCallRecords,
        showAddCallConfirmationModal,
        showUpdateCallDetailsPopup,
        setRefreshCallRecordsStatus,
        refreshCallRecords,
        setShowUpdateCallDetailsPopup,
        setShowAddCallConfirmationModal
      }}
    >
      {children}
    </MeetingModalContext.Provider>
  );
};
