import { message, notification } from "antd";
import axios, { AxiosRequestConfig } from "axios";
import jwtDecode from "jwt-decode";
import moment from "moment";
import { CallLogsList } from "pages/callLogs/callLogs";
import { CallRecord } from "pages/callRecords/CallRecordList";

import {
  AllMessagesList,
  AllPatientsList,
  ProvidersList,
  ChargesList,
  DiagnosesList,
  MessageType,
  UserData,
  PatientProfileData,
  ProviderProfile,
  RiskProfileResponseData,
  SymptomsListResponse,
  AllBillsList,
  AllProviderList,
  CallRecordNote,
  CallRecordBody,
  AddCallRecordResponse,
  AddBillBody,
  AddFallsBody,
  MonthlyTotalCallTime,
  EditBillBody,
  LabData,
  AddProviderBody,
  FluidMetricsResponse,
  ActiveMedicationResponse,
  BloodPressureVitals,
  WeightVitals,
  PatientProfile,
  MedicationHistoryResponse,
  DiscontinuedMedicationListResponse,
  MedicationAutocompleteResponse,
  CheckMedicineDuplicateResponse,
  SaveMedicationRequestBody,
  SurveyLinkData,
  ScheduleAppointmentRequestBody,
  AddNauseaBody,
  AddFeverBody,
  AddShortnessOfBreathBody,
  AddLegSwellingBody,
  AddWeightChangeBody,
  AddFatigueBody,
  AddChestPainBody,
  AddAchesAndPainsBody,
  AddLightheadednessBody,
  AddUlcersBody,
  AddAppetiteImpairmentBody,
  AddMoodImpairmentBody,
  AddUrinaryBody,
  AddDialysisBody,
  AddVitalSignsBody,
  GetUserChannelsResponse,
  GetChannelResponse,
  JoinMeetingInfo,
  CreateMeetingResponse,
  AllChatsResponse,
  AllChatsPaginationData,
  MedicationReasonsDiscontinuedResponse,
  UserNetworkList,
  ChannelMessagesResponse,
  ResetPasswordResponse,
  AllNotificationProps,
  MedicationData,
  ConformanceResponse,
  OAuthTokenResponse,
  SurveyResponseDict,
  CreateEpicTokenResponse,
  AssignSurvey,
  NotificationItem,
  TwilioTokenInfo,
  TwilioCallersInfo,
  NotificationProps,
  SetThresholdBody,
  SetAssestedBody
} from "./sharedTypes";

const BASE_URL = process.env["REACT_APP_API_ENDPOINT"];
interface ApiClient {
  readonly getPamSurveyReport: (body: any) => Promise<any>;

  readonly getKeyWordSearch: (keyword: any) => Promise<any>;

  readonly getSelectedAnswer: (id: any) => Promise<any>;

  readonly getSingleCategory: (id: any) => Promise<any>;

  readonly getCategoricalData: () => Promise<any>;

  readonly postPamSurvey: (
    patient_int_id: any,
    survey_id: any,
    body: any
  ) => Promise<any>;

  readonly getDeclineDeviceAcknowledgement: (
    patient_int_id: any
  ) => Promise<any>;

  readonly getDetails99454: (
    patient_int_id: any,
    month: any,
    year: any
  ) => Promise<any>;

  readonly getPatientBillingData: (
    provider_id: number | undefined,
    patient_internal_id: number | undefined,
    start_date: string | undefined,
    end_date: string | undefined
  ) => Promise<any>;

  readonly getDeclinedConsentAfterLogin: (
    consentName: string | undefined
  ) => Promise<any>;

  readonly getValidateLoginMail: (
    consent_token: string | undefined
  ) => Promise<any>;

  readonly postConsentData: (
    consent_token: string | undefined,
    signer_name: string | undefined,
    signer_relationship: string | undefined,
    consent_name: string | undefined,
    pdffiledata: string | undefined,
    sign_type: string | undefined
  ) => Promise<any>;

  readonly getConsent: (token: string | undefined) => Promise<any>;

  readonly getDeclinedConsent: (
    token: string | undefined,
    consent_name: string | undefined
  ) => Promise<any>;

  readonly postConsentAfterLogin: (
    pdffiledata: string | undefined,
    signer_relationship: string | undefined,
    consent_name: string | undefined,
    sign_type: string | undefined
  ) => Promise<any>;

  readonly getConsentAfterLogin: () => Promise<any>;

  readonly uploadPatientSurvey: (
    type: string,
    file: string,
    id: number,
    dateSelected: any
  ) => Promise<any>;
  readonly getDiagnosesList: (
    loadUpdatedData?: boolean,
    orgId?: any
  ) => Promise<DiagnosesList[]>;

  readonly getChargesList: (
    loadUpdatedData?: boolean
  ) => Promise<ChargesList[]>;

  readonly getMedicalReasonsList: (
    loadUpdatedData?: boolean
  ) => Promise<string[]>;

  readonly getAllPatientsList: (
    searchValue?: string,
    patientType?: string,
    diagnosesCodes?: string,
    vbcPlan?: string,
    loadUpdatedData?: boolean,
    pageNumber?: number,
    offset?: number
  ) => Promise<AllPatientsList[]>;

  readonly getAllPhysiciansList: (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => Promise<ProvidersList[]>;

  readonly getAllCaseManagersList: (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => Promise<ProvidersList[]>;

  readonly getAllNursesList: (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => Promise<ProvidersList[]>;

  readonly getAllBillsList: (
    patientId: string,
    providerId: string,
    loadUpdatedData?: boolean
  ) => Promise<AllBillsList[]>;

  readonly getAllMessagesList: (
    searchNameValue: string,
    searchSpecialtyValue: string,
    messageType: string,
    loadUpdatedData?: boolean
  ) => Promise<AllMessagesList[]>;

  readonly getMessageHistory: (
    otherUserId: string,
    aboutUserId?: string,
    loadUpdatedData?: boolean
  ) => Promise<MessageType[]>;

  readonly getLoggedInUserData: () => Promise<UserData>;
  readonly getCareTeamList: (
    selectedPatientId: string | undefined,
    searchNameValue: string,
    searchSpecialtyValue: string,
    providerType: string,
    loadUpdatedData?: boolean
  ) => Promise<PatientProfile>;
  readonly getPatientProfile: (
    selectedPatientId: string | undefined,
    loadUpdatedData?: boolean
  ) => Promise<PatientProfileData>;
  readonly getProviderProfile: (
    selectedProviderId: string | number,
    searchText: string,
    loadUpdatedData?: boolean
  ) => Promise<ProviderProfile>;
  readonly getPatientRiskProfile: (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => Promise<RiskProfileResponseData[]>;
  readonly getPatientSymptoms: (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => Promise<SymptomsListResponse[]>;
  readonly getCallRecords: (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => Promise<CallRecord[]>;
  readonly getCallLogs: (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => Promise<CallLogsList[]>;
  readonly getAllProvidersList: (
    patientId: string | number,
    searchValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => Promise<AllProviderList[]>;
  readonly addProviders: (
    dbId: number,
    data: AddProviderBody
  ) => Promise<string>;
  readonly getCallRecordNotes: () => Promise<CallRecordNote[]>;
  readonly postCallRecord: (
    body: CallRecordBody
  ) => Promise<AddCallRecordResponse>;
  readonly putCallRecord: (
    patientId: string,
    callId: string | number,
    body: CallRecordBody | undefined
  ) => Promise<string>;
  readonly deleteCallRecord: (
    patientId: string,
    callId: string | number
  ) => Promise<string>;
  readonly addBill: (data: AddBillBody) => Promise<string>;
  readonly addFallsFormData: (data: AddFallsBody) => Promise<string>;
  readonly addNauseaFormData: (data: AddNauseaBody) => Promise<string>;
  readonly addUrinaryFormData: (data: AddUrinaryBody) => Promise<string>;
  readonly addVitalSignsFormData: (data: AddVitalSignsBody) => Promise<string>;
  readonly addDialysisFormData: (data: AddDialysisBody) => Promise<string>;
  readonly addFeverFormData: (data: AddFeverBody) => Promise<string>;
  readonly addShortnessOfBreathFormData: (
    data: AddShortnessOfBreathBody
  ) => Promise<string>;
  readonly addLegSwellingFormData: (
    data: AddLegSwellingBody
  ) => Promise<string>;
  readonly addWeightChangeFormData: (
    data: AddWeightChangeBody
  ) => Promise<string>;

  readonly addFatigueFormData: (data: AddFatigueBody) => Promise<string>;
  readonly addChestPainFormData: (data: AddChestPainBody) => Promise<string>;
  readonly addAchesAndPainsFormData: (
    data: AddAchesAndPainsBody
  ) => Promise<string>;
  readonly addLightheadednessFormData: (
    data: AddLightheadednessBody
  ) => Promise<string>;
  readonly addUlcersFormData: (data: AddUlcersBody) => Promise<string>;
  readonly addAppetiteImpairmentFormData: (
    data: AddAppetiteImpairmentBody
  ) => Promise<string>;
  readonly addMoodImpairmentFormData: (
    data: AddMoodImpairmentBody
  ) => Promise<string>;
  readonly editBill: (
    billId: string,
    bodyData: EditBillBody
  ) => Promise<string>;
  readonly deleteBill: (billId: string) => Promise<string>;
  readonly getTotalMonthlyCallTime: (
    patientId: string | number,
    providerId: string | number,
    year: string,
    month: string,
    loadUpdatedData?: boolean
  ) => Promise<MonthlyTotalCallTime>;
  readonly getLabData: (
    patientId: string,
    loadUpdatedData?: boolean
  ) => Promise<LabData[]>;
  readonly getFluidMetricsData: (
    patientId: string | number,
    loadUpdatedData?: boolean
  ) => Promise<FluidMetricsResponse[]>;
  readonly getActiveMedicationList: (
    patientId: string | number,
    searchText: string,
    medReasons: string,
    loadUpdatedData?: boolean
  ) => Promise<ActiveMedicationResponse>;
  readonly getRemoteVitalsData: (
    patientId: string,
    type: string,
    timeRange: string,
    timeZone: string,
    loadUpdatedData?: boolean
  ) => Promise<Array<BloodPressureVitals | WeightVitals>>;
  readonly getDisclaimer: () => Promise<any>;
  readonly getProviderTerms: () => Promise<any>;
  readonly getPatientTerms: () => Promise<any>;
  readonly getProvidersEULA: () => Promise<any>;
  readonly getPatientsEULA: () => Promise<any>;
  readonly getPrivacyPolicy: () => Promise<any>;
  readonly getMedicationHistoryList: (
    patientId: string | number,
    medicineId: string,
    loadUpdatedData?: boolean
  ) => Promise<MedicationHistoryResponse[]>;
  readonly getDiscontinuedMedicationList: (
    patientId: string | number,
    searchText: string,
    medReasons: string,
    loadUpdatedData?: boolean
  ) => Promise<DiscontinuedMedicationListResponse>;
  readonly getMedicineAutocomplete: (
    searchTerm: string,
    maxResults: number
  ) => Promise<MedicationAutocompleteResponse[]>;
  readonly getMedicineUnits: () => Promise<string[]>;
  readonly getMedicineSig: () => Promise<string[]>;
  readonly getMedicineDuration: () => Promise<string[]>;
  readonly getMedicineInfoFrom: () => Promise<string[]>;
  readonly checkMedicineDuplicate: (
    patientId: string | number,
    medicineId: string
  ) => Promise<CheckMedicineDuplicateResponse>;
  readonly saveMedication: (params: {
    patientId: string | number;
    medicationData: MedicationData;
    isEdit?: boolean;
  }) => Promise<any>;
  readonly discontinueMedication: (params: {
    patientId: string | number;
    productId: string;
    reasons: { id: number | string; reason: string }[];
  }) => Promise<string>;
  readonly deleteMedication: (params: {
    patientId: string | number;
    productId: string;
  }) => Promise<string>;
  readonly getSurveyLinks: (
    patientId: string | number
  ) => Promise<SurveyLinkData[]>;
  readonly scheduleAppointment: (
    body: ScheduleAppointmentRequestBody
  ) => Promise<any>;
  readonly resetPassword: (userId: string) => Promise<ResetPasswordResponse>;
  readonly getAppointmentList: (patientId: string | number) => Promise<any>;
  readonly removeAppointment: (appointmentId: number) => Promise<any>;
  readonly getUserChannels: (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean
  ) => Promise<GetUserChannelsResponse>;
  readonly updatePatientParticipation: (
    patient_uname: string,
    prv_uname: string,
    isPatientEnabled: boolean
  ) => Promise<string>;
  readonly getChannel: (
    pat_uname: string,
    prv1_uname: string,
    isPatientEnabled: boolean,
    prv2_uname?: string
  ) => Promise<GetChannelResponse | string>;
  readonly createMeeting: (
    internalId: string,
    targetInternalId: string,
    pstnPhoneNumber?: string
  ) => Promise<JoinMeetingInfo>;
  readonly endMeeting: (meetingId: string) => Promise<string>;
  readonly endTwilioMeeting: (meetingId: string) => Promise<string>;
  readonly startTwilioMeeting: (owner_id: string) => Promise<string>;
  readonly joinMeeting: (
    meetingId: string,
    internalId: string
  ) => Promise<JoinMeetingInfo>;
  readonly getTwilioToken: () => Promise<TwilioTokenInfo>;
  readonly getTwilioCallers: () => Promise<TwilioCallersInfo>;
  readonly activeMeeting: (
    internalId: string
  ) => Promise<string | CreateMeetingResponse>;
  readonly getAllChats: (
    username: string,
    pagination?: AllChatsPaginationData[] | null,
    maxResults?: number,
    channelRoles?: string,
    includePatientLinkedChats?: boolean
  ) => Promise<AllChatsResponse>;
  readonly getMedicationDiscontinuedReasons: () => Promise<
    MedicationReasonsDiscontinuedResponse[]
  >;
  readonly getUserNetworkList: (
    userDBId: string | number,
    userType: string,
    loadUpdatedData?: boolean
  ) => Promise<UserNetworkList[]>;

  readonly getNotifications: (
    userId: string,
    userType: string,
    from_date?: string,
    to_date?: string,
    notification_status?: string
  ) => Promise<AllNotificationProps>;
  readonly putNotifications: (params: {
    notificationId: string | number;
    body: { status: string; type: string };
  }) => Promise<string>;
  readonly listChannelMessages: (
    channel_name: string,
    next_token?: string | null,
    max_results?: number
  ) => Promise<ChannelMessagesResponse>;
  readonly getFhirMetaData: (issQuery: string) => Promise<ConformanceResponse>;
  readonly postEpicTokenResponse: (
    loginCredsObj: OAuthTokenResponse
  ) => Promise<CreateEpicTokenResponse>;
  readonly getPatientSurveyList: (patient_internal_id: string) => Promise<any>;
  readonly getUploadedSurvey: (patient_internal_id: number) => Promise<any>;
  readonly postSurveyResponse: (
    body: SurveyResponseDict,
    patient_internal_id: any,
    surveyId: any
  ) => Promise<any>;
  readonly getReviewSurveyResponse: (
    patient_internal_id: number
  ) => Promise<any>;
  readonly getPatientSurveyNotification: (
    patient_internal_id: number
  ) => Promise<any>;
  readonly getVBCPrograms: () => Promise<any>;
  readonly getAllPatientSurveyList: () => Promise<any>;
  readonly postAssignSurvey: (body: AssignSurvey) => Promise<any>;
  readonly putAllNotifications: (params: {
    notification_obj: NotificationItem[];
    status: string;
  }) => Promise<any>;

  readonly getEditSurvey: (
    patientInternalId?: string | number,
    surveyId?: string | number
  ) => Promise<any>;

  readonly closeEditSurvey: (
    patientInternalId?: string | number,
    surveyId?: string | number
  ) => Promise<any>;
  readonly verifyVCToken: (tokenData: TokenData) => Promise<any>;
  // readonly verifyVCToken: (token_data: string) => Promise<any>;
  readonly setThresholdData: (data: SetThresholdBody) => Promise<any>;
  readonly getThresholdData: (
    patientInternalId?: string | number
  ) => Promise<any>;
  readonly setAlertAssisted: (data: SetAssestedBody) => Promise<any>;
  readonly getBillingsData: () => Promise<any>;
  readonly getDisableContent: (orgId: any) => Promise<any>;
}

interface TokenData {
  token_data: string;
}

const PATHS = Object.freeze({
  POST_THRESHOLD_DATA: "patient/bp_threshold",
  POST_ASSISTED_DATA: "patient/alert-assisted",
  GET_THRESHOLD_DATA: "patient/get_bp_threshold",
  GET_SEARCHED_KEYWORD: "/education/search?text=",
  GET_SELECTED_ANSWER: "/education/title/document/",
  GET_SINGLE_CATEGORY: "/education/category/title/",
  GET_ALL_CATEGORICAL_DATA: "education/get_categorical_data",
  POST_PAM_SURVEY: "survey/save_action_response",
  GET_DECLINE_DEVICE_ACKNOWLEDGEMENT: "users/device_declined",
  GET_DETAILS_99454: "users/99454_billing",
  GET_PATIENT_BILLING_DATA: "users/provider/remote_monitoring_report",
  GET_CONSENT_DECLINE_MAIL_AFTER_LOGIN: "users/decline_email",
  POST_CONSENT_AFTER_LOGIN: "users/store_consent_data",
  GET_CONSENTS_AFTER_LOGIN: "users/checkConsent",
  GET_VALIDATE_LOGIN_MAIL: "consent/validate_login_mail_sent",
  POST_CONSENT_DATA: "consent/store_consent_data",
  GET_DECLINE_CONSENT_DATA: "consent/send_consent_decline_email",
  GET_CONSENT_DATA: "consent/getconsent",
  POST_PATIENT_SURVEY: "survey/upload_patient_survey",
  GET_DIAGNOSIS_LIST: "billing/diagnose",
  GET_CHARGES_LIST: "billing/charge",
  GET_ALL_PATIENTS_LIST: "patient/patients",
  GET_ALL_PHYSICIANS_LIST: "provider/physicians",
  GET_ALL_CASE_MANAGERS_LIST: "provider/case_managers",
  GET_ALL_NURSES_LIST: "provider/nurses",
  GET_ALL_MESSAGES_LIST: "messages/message",
  GET_MESSAGE_HISTORY: "messages/history",
  GET_LOGGED_IN_USER_DATA: "users/auth",
  GET_CARE_TEAM_LIST: "patient/patients",
  GET_USER_NETWORK_INFO: "users/patient/network",
  GET_PROVIDER_PROFILE: (providerId: string | number) =>
    `provider/profile/${providerId}`,
  GET_RISK_PROFILE: (patientId: string | number) =>
    `patient/risk_profile/${patientId}`,
  GET_PATIENT_SYMPTOMS: (patientId: string | number) =>
    `patient/medicalinfo/symptoms/${patientId}`,
  GET_ALL_BILLS_LIST: (
    patientId: string | number,
    providerId: string | number
  ) => `billing/billings/${patientId}/${providerId}`,
  GET_CALL_RECORDS: (patientId: string | number) =>
    `call/call_records/${patientId}`,
  GET_CALL_LOGS: (patientId: string | number) => `call/call_logs/${patientId}`,
  GET_ALL_PROVIDERS_LIST: "users/providers",
  GET_CALL_RECORD_NOTES: "call/call_logs/notes",
  POST_CALL_RECORD: "call/call_logs",
  PUT_CALL_RECORD: (patientId: string | number, callId: string) =>
    `call/call_logs/${patientId}/${callId}`,
  DELETE_CALL_RECORD: (patientId: string | number, callId: string) =>
    `call/call_logs/${patientId}/${callId}`,
  ADD_BILL: "billing",
  EDIT_BILL: "billing",
  ADD_FALLS: "patient/symptoms/falls",
  ADD_NAUSEA: "patient/symptoms/nausea",
  ADD_URINARY_SYMPTOMS: "patient/symptoms/urinary",
  ADD_VITAL_SIGNS: "patient/symptoms/vital",
  ADD_FEVER: "patient/symptoms/fever",
  ADD_SHORTNESS_OF_BREATH: "patient/symptoms/breath",
  ADD_LEG_SWELLING: "patient/symptoms/swelling",
  ADD_WEIGHT_CHANGE: "patient/symptoms/weightchange",
  ADD_FATIGUE: "patient/symptoms/fatigue",
  ADD_CHEST_PAIN: "patient/symptoms/chestpain",
  ADD_ACHES_PAIN: "patient/symptoms/pain",
  ADD_LIGHTHEADEDNESS: "patient/symptoms/lightheadedness",
  ADD_ULCERS: "patient/symptoms/ulcers",
  ADD_APPETITE_IMPAIRMENT: "patient/symptoms/appetite",
  ADD_MOOD_IMPAIRMENT: "patient/symptoms/mood",
  ADD_DIALYSIS_SYMPTOMS: "patient/symptoms/dialysis",
  GET_TOTAL_MONTHLY_CALL_TIME: "call/call_logs/total/",
  ADD_PROVIDERS: "users/patient/network",
  GET_LAB_DATA: "patient/labdata",
  GET_FLUID_METRICS: (patientId: string | number) =>
    `patient/patients/fluid_metric/${patientId}`,
  GET_ACTIVE_MEDICATION: (patientId: string | number) =>
    `medication/activeMedication/${patientId}`,
  GET_REMOTE_VITALS_DATA: "patient/vital_signs",
  GET_DISCLAIMER: "users/disclaimer",
  GET_PROVIDER_TERMS: "users/termsProviders",
  GET_PATIENT_TERMS: "users/termsPatients",
  GET_EULA_PROVIDERS: "users/eulaProviders",
  GET_EULA_PATIENTS: "users/eulaPatients",
  GET_PRIVACY_POLICY: "users/privacy",
  GET_MEDICATION_HISTORY: (patientId: string | number, medicineId: string) =>
    `/medication/history/${patientId}/${medicineId}`,
  GET_DISCONTINUED_MEDICATION: (patientId: string | number) =>
    `medication/inactiveMedication/${patientId}`,
  GET_MEDICAL_REASONS_LIST: "medication/medreasons",
  GET_MEDICATION_AUTOCOMPLETE: "medication/product_names",
  GET_MEDICATION_UNITS: "medication/unit",
  GET_MEDICATION_SIG: "medication/sig",
  GET_MEDICATION_DURATION: "medication/duration",
  GET_MEDICATION_INFO_FORM: "medication/info_from",
  CHECK_MEDICATION_DUPLICATE: (
    patientId: string | number,
    medicineId: string
  ) => `medication/dup_check/${patientId}/${medicineId}`,
  SAVE_MEDICATION: (patientId: string | number) => `medication/${patientId}`,
  DISCONTINUE_MEDICATION: (patientId: string | number, medicationId: string) =>
    `medication/discontinue/${patientId}/${medicationId}`,
  DELETE_MEDICATION: (patientId: string | number, medicationId: string) =>
    `medication/${patientId}/${medicationId}`,
  GET_SURVEY_LINKS: (patientId: string | number) =>
    `patient/survey/survey/${patientId}`,
  SCHEDULE_APPOINTMENT: "appointment/schedule",
  RESET_PASSWORD: (userId: string) =>
    `users/forgot_password?username=${userId}&portal=WebApp`,
  GET_APPOINTMENT_LIST: (patientId: string | number) =>
    `appointment/list/${patientId}`,
  REMOVE_APPOINTMENT: (appointmentId: number) =>
    `appointment/remove/${appointmentId}`,
  GET_USER_CHANNELS: (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean
  ) =>
    `messages/userChannel?username=${username}&include_patient_linked_chats=${includePatientLinkedChats}${
      sendLastMessage ? `&sendLastMessage=True` : ""
    }`,
  UPDATE_PATIENT_PARTICIPATION: (
    patient_uname: string,
    prv_uname: string,
    isPatientEnabled: boolean
  ) =>
    `messages/patientParticipation?patient_username=${patient_uname}&username_1=${prv_uname}&is_patient_enabled=${isPatientEnabled}`,
  GET_CHANNEL: (
    pat_uname: string,
    user1_uname: string,
    isPatientEnabled: boolean,
    user2_uname?: string
  ) =>
    `messages/channel?patient_username=${pat_uname}&is_patient_enabled=${isPatientEnabled}&username_1=${user1_uname}${
      user2_uname ? `&username_2=${user2_uname}` : ""
    }`,
  CREATE_MEETING: (internalId: string) =>
    `messages/createMeeting/${internalId}`,
  GET_TWILIO_TOKEN: () => `${BASE_URL}/messages/twilio-generate`,
  START_TWILIO_MEETING: (owner_id: string) =>
    `${BASE_URL}/messages/twilio-end-meeting/${owner_id}`,
  END_TWILIO_MEETING: (meetingId: string) =>
    `${BASE_URL}/messages/twilio-end-meeting/${meetingId}`,
  GET_CALLERS_ID: () => `${BASE_URL}/messages/get-org-caller`,
  END_MEETING: (meetingId: string) => `messages/endMeeting/${meetingId}`,
  JOIN_MEETING: (meetingId: string, internalId: string) =>
    `messages/joinMeeting/${meetingId}/${internalId}`,
  ACTIVE_MEETING: (internalId: string, duration?: string) =>
    `messages/activeMeeting/${internalId}${
      duration ? `?duration=${duration}` : ""
    }`,
  GET_ALL_CHATS: "messages/allChats",
  GET_MEDICATION_DISCONTINUED_REASONS: "/medication/discontinue",
  GET_NOTIFICATIONS: (
    userId: string,
    userType: string,
    from_date?: string,
    to_date?: string,
    notification_status?: string
  ) =>
    `notification/${userId}?user_type=${userType}${
      !from_date || !to_date ? "" : `&from_date=${from_date}&to_date=${to_date}`
    }${
      notification_status ? `notification_status=${notification_status}` : ""
    }`,
  PUT_NOTIFICATION: (notificationId: string | number) =>
    `notification/${notificationId}`,
  LIST_CHANNEL_MESSAGES: "messages/channelMessages",
  GET_FHIR_METADATA: (issQuery: string) => `${issQuery}/metadata`,
  POST_EPIC_TOKEN: "consent/patient/create_epic_token",
  PATIENT_SURVEY_LIST: "survey/get_patient_survey",
  PATIENT_SURVEY: "survey/save_surveys",
  UPLOADED_PATIENT_SURVEY: "survey/get_uploaded_survey",
  RESPONSE_PATIENT_SURVEY: "survey/review_complete",
  PATIENT_SURVEY_NOTIFICATION: "survey/get_reviewed_survey_data",
  MARK_ALL_NOTIFICATIONS: () => "notification/mark_all_notifications",
  VBC_LIST: `org/vbc/{:org_id}`,
  ALL_SURVEY_LIST: "survey/get_all_survey",
  ASSIGN_SURVEY: "survey/assign_survey_to_patient",
  GET_EDIT_SURVEY: "survey/edit_survey",
  EDIT_SURVEY_CLOSE: "survey/verify",
  VERIFY_VC_TOKEN: "consent/verify/meeting_token",
  GET_SURVEY_REPORT: "/survey/get_survey_report",
  GET_BILLINGS_DATA: "billing/reports",
  GET_DISABLE_COMPONENT: "users/disable_content"
});

export const createApiClient = (accessToken?: string): ApiClient => {
  const config = {
    baseURL: BASE_URL,
    headers: {
      Authorization: accessToken
        ? accessToken
        : sessionStorage.getItem("access_token") || ""
    }
  };

  const checkTokenExpiry = () => {
    //Added logout functionality here rather than the userContext hook since hooks can only be called in components or custom hooks
    const token: any = jwtDecode(sessionStorage.getItem("access_token") || "");
    if (moment().valueOf() >= token.exp * 1000) {
      const rememberMe = sessionStorage.getItem("login_remember");
      const userName = sessionStorage.getItem("login_username");
      sessionStorage.clear();
      sessionStorage.setItem("login_remember", String(rememberMe));
      if (rememberMe) {
        sessionStorage.setItem("login_username", String(userName));
      }
      window.location.pathname = "/";
      message.warning("Your sessions has expired, please login again.");
    }
  };

  const getDiagnosesList = async (loadUpdatedData?: boolean, orgId?: any) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.GET_DIAGNOSIS_LIST}/${orgId}`, {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now()
            }
          : null
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicalReasonsList = async (loadUpdatedData?: boolean) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICAL_REASONS_LIST, {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now()
            }
          : null
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getChargesList = async (loadUpdatedData?: boolean) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_CHARGES_LIST, {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now()
            }
          : null
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllPatientsList = async (
    searchValue?: string,
    patientType?: string,
    diagnosesCodes?: string,
    vbcPlan?: string,
    loadUpdatedData?: boolean,
    pageNumber?: number,
    offset?: number
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_PATIENTS_LIST}?page=${pageNumber}&page_size=${offset}${
          searchValue ? `&searchQuery=${searchValue}` : ""
        }${
          patientType === "Remote Monitoring" ? "&remote_monitoring=Y,I" : ""
        }${
          vbcPlan == "-1" || vbcPlan == "Any" ? "" : `&vbc_check=${vbcPlan}`
        }${`&codes=${diagnosesCodes}`}${`&page=${
          pageNumber === 0 ? "" : pageNumber
        }`}${`&page_size=${offset === 0 ? "" : offset}`}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
                // ...(loadUpdatedData && { timeStamp: Date.now() }),
                // ...(searchValue && { searchQuery: searchValue }),
                // ...(patientType === "Remote Monitoring" && {
                //   remote_monitoring: "Y"
                // }),
                // ...(diagnosesCodes && { codes: diagnosesCodes })
              }
            : null
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(JSON.stringify(error.response));
    }
  };

  const getAllCaseManagersList = async (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_CASE_MANAGERS_LIST}?name=${searchNameValue}&specialty=${searchSpecialtyValue}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllNursesList = async (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_NURSES_LIST}?name=${searchNameValue}&specialty=${searchSpecialtyValue}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllPhysiciansList = async (
    searchNameValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_PHYSICIANS_LIST}?name=${searchNameValue}&specialty=${searchSpecialtyValue}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllMessagesList = async (
    searchNameValue: string,
    searchSpecialtyValue: string,
    messageType: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_MESSAGES_LIST}?page=&pageSize=&searchQuery=${searchNameValue}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMessageHistory = async (
    otherUserId: string,
    aboutUserId?: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_MESSAGE_HISTORY}?other_user_id=${otherUserId}${
          aboutUserId ? `&about_user_id=${aboutUserId}` : ""
        }`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getLoggedInUserData = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_LOGGED_IN_USER_DATA, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getCareTeamList = async (
    selectedPatientId: string | undefined,
    searchNameValue: string,
    searchSpecialtyValue: string,
    providerType: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CARE_TEAM_LIST}/${selectedPatientId}?name_filter=${searchNameValue}&specialty=${searchSpecialtyValue}&proles=${providerType}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  };

  const getPatientProfile = async (
    selectedPatientId: string | undefined,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CARE_TEAM_LIST}/${selectedPatientId}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  };

  const getProviderProfile = async (
    selectedProviderId: string | number,
    searchText: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_PROVIDER_PROFILE(selectedProviderId),
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now(),
                name_filter: searchText
              }
            : { name_filter: searchText }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getPatientRiskProfile = async (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_RISK_PROFILE(selectedPatientId),
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getPatientSymptoms = async (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_PATIENT_SYMPTOMS(selectedPatientId),
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllBillsList = async (
    patientId: string | number,
    providerId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_ALL_BILLS_LIST(patientId, providerId),
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getAllProvidersList = async (
    patientId: string | number,
    searchValue: string,
    searchSpecialtyValue: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_ALL_PROVIDERS_LIST}?patient_id=${patientId}&name_filter=${searchValue}&specialty=${searchSpecialtyValue}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getCallRecords = async (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CALL_RECORDS(selectedPatientId)}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addBill = async (bodyData: AddBillBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(`${PATHS.ADD_BILL}/`, bodyData, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addFallsFormData = async (bodyData: AddFallsBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(`${PATHS.ADD_FALLS}`, bodyData, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addNauseaFormData = async (bodyData: AddNauseaBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_NAUSEA}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addUrinaryFormData = async (bodyData: AddUrinaryBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_URINARY_SYMPTOMS}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addVitalSignsFormData = async (bodyData: AddVitalSignsBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_VITAL_SIGNS}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addDialysisFormData = async (bodyData: AddDialysisBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_DIALYSIS_SYMPTOMS}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addFeverFormData = async (bodyData: AddFeverBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(`${PATHS.ADD_FEVER}`, bodyData, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addShortnessOfBreathFormData = async (
    bodyData: AddShortnessOfBreathBody
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_SHORTNESS_OF_BREATH}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addLegSwellingFormData = async (bodyData: AddLegSwellingBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_LEG_SWELLING}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addWeightChangeFormData = async (bodyData: AddWeightChangeBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_WEIGHT_CHANGE}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addFatigueFormData = async (bodyData: AddFatigueBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_FATIGUE}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addChestPainFormData = async (bodyData: AddChestPainBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_CHEST_PAIN}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addAchesAndPainsFormData = async (bodyData: AddAchesAndPainsBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_ACHES_PAIN}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addLightheadednessFormData = async (
    bodyData: AddLightheadednessBody
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_LIGHTHEADEDNESS}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addUlcersFormData = async (bodyData: AddUlcersBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_ULCERS}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addAppetiteImpairmentFormData = async (
    bodyData: AddAppetiteImpairmentBody
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_APPETITE_IMPAIRMENT}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addMoodImpairmentFormData = async (bodyData: AddMoodImpairmentBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.ADD_MOOD_IMPAIRMENT}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const addProviders = async (dbId: number, bodyData: AddProviderBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        `${PATHS.ADD_PROVIDERS}/${dbId}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getCallLogs = async (
    selectedPatientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CALL_LOGS(selectedPatientId)}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getCallRecordNotes = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CALL_RECORD_NOTES}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const postCallRecord = async (body: CallRecordBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(PATHS.POST_CALL_RECORD, body, config);
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const putCallRecord = async (
    patientId: string,
    callId: string | number,
    body: CallRecordBody | undefined
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.PUT_CALL_RECORD(patientId, String(callId)),
        body,
        config
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const deleteCallRecord = async (
    patientId: string,
    callId: string | number
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.delete(
        `${PATHS.DELETE_CALL_RECORD(patientId, String(callId))}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getTotalMonthlyCallTime = async (
    patientId: string | number,
    providerId: string | number,
    year: string,
    month: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const timestamp = loadUpdatedData ? Date.now() : undefined;
      const response = await axios.get(
        `${PATHS.GET_TOTAL_MONTHLY_CALL_TIME}${patientId}/${providerId}/${year}/${month}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: timestamp
                  ? new Date(timestamp).toISOString()
                  : undefined
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const deleteBill = async (billId: string) => {
    checkTokenExpiry();
    try {
      const response = await axios.delete(`${PATHS.EDIT_BILL}/${billId}`, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const editBill = async (billId: string, bodyData: EditBillBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        `${PATHS.EDIT_BILL}/${billId}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error: any) {
      return error.response.data.detail;
    }
  };

  const getLabData = async (patientId: string, loadUpdatedData?: boolean) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.GET_LAB_DATA}/${patientId}`, {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now()
            }
          : null
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getFluidMetricsData = async (
    patientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_FLUID_METRICS(patientId), {
        ...config,
        params: loadUpdatedData
          ? {
              timeStamp: Date.now()
            }
          : null
      });

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getRemoteVitalsData = async (
    patientId: string,
    type: string,
    timeRange: string,
    timeZone: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const startDate = moment()
        .subtract(timeRange, "months")
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        .toISOString();

      const response = await axios.get(
        `${PATHS.GET_REMOTE_VITALS_DATA}/${patientId}?type=${type}&start_date=${startDate}&time_zone=${timeZone}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getActiveMedicationList = async (
    patientId: string | number,
    searchText: string,
    medReasons: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      if (patientId != "") {
        const response = await axios.get(
          PATHS.GET_ACTIVE_MEDICATION(patientId),
          {
            ...config,
            params: {
              ...(loadUpdatedData && { timeStamp: Date.now() }),
              ...(searchText?.length > 1 && { name_filter: searchText }),
              ...(medReasons?.length > 0 && { med_reasons: medReasons })
            }
          }
        );
        return response.data;
      }
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getDisclaimer = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_DISCLAIMER, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getProviderTerms = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_PROVIDER_TERMS, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getPatientTerms = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_PATIENT_TERMS, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getProvidersEULA = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_EULA_PROVIDERS, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getPatientsEULA = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_EULA_PATIENTS, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getPrivacyPolicy = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_PRIVACY_POLICY, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicationHistoryList = async (
    patientId: string | number,
    medicineId: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_MEDICATION_HISTORY(patientId, medicineId),
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getDiscontinuedMedicationList = async (
    patientId: string | number,
    searchText: string,
    medReasons: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_DISCONTINUED_MEDICATION(patientId),
        {
          ...config,
          params: {
            ...(loadUpdatedData && { timeStamp: Date.now() }),
            ...(searchText?.length > 1 && { name_filter: searchText }),
            ...(medReasons?.length > 0 && { med_reasons: medReasons })
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicineAutocomplete = async (
    searchTerm: string,
    maxResults: number
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICATION_AUTOCOMPLETE, {
        ...config,
        params: {
          name: searchTerm,
          max_results: maxResults
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicineUnits = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICATION_UNITS, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicineSig = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICATION_SIG, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicineDuration = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICATION_DURATION, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicineInfoFrom = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_MEDICATION_INFO_FORM, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const checkMedicineDuplicate = async (
    patientId: string | number,
    medicineId: string
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.CHECK_MEDICATION_DUPLICATE(patientId, medicineId),
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const saveMedication = async (params: {
    patientId: string | number;
    medicationData: MedicationData;
    isEdit?: boolean;
  }) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.SAVE_MEDICATION(params.patientId),
        params.medicationData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const discontinueMedication = async (params: {
    patientId: string | number;
    productId: string;
    reasons: { id: number | string; reason: string }[];
  }) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.DISCONTINUE_MEDICATION(params.patientId, params.productId),
        { discontinue_reason: params.reasons },
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const deleteMedication = async (params: {
    patientId: string | number;
    productId: string;
  }) => {
    checkTokenExpiry();
    try {
      const response = await axios.delete(
        PATHS.DELETE_MEDICATION(params.patientId, params.productId),
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getSurveyLinks = async (patientId: string | number) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_SURVEY_LINKS(patientId),
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const scheduleAppointment = async (body: ScheduleAppointmentRequestBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.SCHEDULE_APPOINTMENT,
        body,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const resetPassword = async (userId: string) => {
    try {
      const response = await axios.get(PATHS.RESET_PASSWORD(userId), config);
      return response.data;
    } catch (error) {
      throw new Error(String(error));
    }
  };

  const getAppointmentList = async (
    patientId: string | number,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_APPOINTMENT_LIST(patientId)}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const removeAppointment = async (appointmentId: number) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.REMOVE_APPOINTMENT(appointmentId),
        {},
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getUserChannels = async (
    username: string,
    includePatientLinkedChats: boolean,
    sendLastMessage?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_USER_CHANNELS(
          username,
          includePatientLinkedChats,
          sendLastMessage
        )}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const updatePatientParticipation = async (
    patient_uname: string,
    prv_uname: string,
    isPatientEnabled: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.UPDATE_PATIENT_PARTICIPATION(
          patient_uname,
          prv_uname,
          isPatientEnabled
        ),
        null,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getChannel = async (
    pat_uname: string,
    user1_uname: string,
    isPatientEnabled: boolean,
    user2_uname?: string
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_CHANNEL(
          pat_uname,
          user1_uname,
          isPatientEnabled,
          user2_uname
        )}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const createMeeting = async (
    internalId: string,
    targetInternalId: string,
    pstnPhoneNumber?: string
  ): Promise<JoinMeetingInfo> => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.CREATE_MEETING(internalId),
        pstnPhoneNumber
          ? { participant_list: [targetInternalId], cell: pstnPhoneNumber }
          : { participant_list: [targetInternalId] },
        config
      );

      return response.data;
    } catch (error: any) {
      throw new Error(error.response.data as string);
    }
  };

  const endMeeting = async (meetingId: string): Promise<string> => {
    checkTokenExpiry();
    try {
      const response = await axios.delete(PATHS.END_MEETING(meetingId), config);

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const startTwilioMeeting = async (owner_id: string): Promise<string> => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.START_TWILIO_MEETING(owner_id),
        config
      );

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };
  const endTwilioMeeting = async (meetingId: string): Promise<string> => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.END_TWILIO_MEETING(meetingId),
        config
      );

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const joinMeeting = async (
    meetingId: string,
    internalId: string
  ): Promise<JoinMeetingInfo> => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.JOIN_MEETING(meetingId, internalId),
        {},
        config
      );

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  // Genrate Twilio Token
  const getTwilioToken = async (): Promise<TwilioTokenInfo> => {
    try {
      const response = await axios.post(PATHS.GET_TWILIO_TOKEN(), {}, config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  // get all twilio callers id
  const getTwilioCallers = async (): Promise<TwilioTokenInfo> => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_CALLERS_ID(), config);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const activeMeeting = async (
    internalId: string,
    duration?: string
  ): Promise<string | CreateMeetingResponse> => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.ACTIVE_MEETING(internalId, duration)}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  interface AllChatsParams {
    username: string;
    pagination?: string;
    max_results?: number;
    channelRoles?: string;
    includePatientLinkedChats?: string;
  }

  const getAllChatsParams = (
    username: string,
    pagination?: AllChatsPaginationData[] | null,
    maxResults?: number,
    channelRoles?: string,
    includePatientLinkedChats?: boolean
  ): AllChatsParams => {
    checkTokenExpiry();
    const finalParams: AllChatsParams = { username };
    if (pagination) {
      finalParams.pagination = JSON.stringify(pagination);
    }
    if (maxResults) {
      finalParams.max_results = maxResults;
    }
    if (channelRoles) {
      finalParams.channelRoles = channelRoles;
    }
    if (includePatientLinkedChats) {
      finalParams.includePatientLinkedChats = includePatientLinkedChats
        ? "True"
        : "False";
    }
    return finalParams;
  };

  const getAllChats = async (
    username: string,
    pagination?: AllChatsPaginationData[] | null,
    maxResults?: number,
    channelRoles?: string,
    includePatientLinkedChats?: boolean
  ): Promise<AllChatsResponse> => {
    checkTokenExpiry();
    try {
      const allChatsConfig: AxiosRequestConfig = {
        ...config,
        params: getAllChatsParams(
          username,
          pagination,
          maxResults,
          channelRoles,
          includePatientLinkedChats
        )
      };
      const response = await axios.get(PATHS.GET_ALL_CHATS, allChatsConfig);
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getMedicationDiscontinuedReasons = async (): Promise<
    MedicationReasonsDiscontinuedResponse[]
  > => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_MEDICATION_DISCONTINUED_REASONS}`,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getUserNetworkList = async (
    userDBId: string | number,
    userType: string,
    loadUpdatedData?: boolean
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_USER_NETWORK_INFO}/${userDBId}`,
        {
          ...config,
          params: loadUpdatedData
            ? {
                timeStamp: Date.now()
              }
            : null
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.status);
    }
  };
  const getNotifications = async (
    userId: string,
    userType: string,
    from_date?: string,
    to_date?: string,
    notification_status?: string
  ): Promise<AllNotificationProps> => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_NOTIFICATIONS(
          userId,
          userType,
          from_date,
          to_date,
          notification_status
        )}`,
        config
      );

      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const putNotifications = async (params: {
    notificationId: string | number;
    body: { status: string; type: string };
  }) => {
    checkTokenExpiry();
    try {
      const response = await axios.put(
        PATHS.PUT_NOTIFICATION(params.notificationId),
        params.body,
        config
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const listChannelMessages = async (
    channel_name: string,
    next_token?: string | null,
    max_results?: number
  ) => {
    checkTokenExpiry();
    const _params = {
      channel_name: channel_name,
      next_token: next_token,
      max_results: max_results
    };

    if (!next_token) {
      delete _params.next_token;
    }
    if (!max_results) {
      delete _params.max_results;
    }

    try {
      const response = await axios.get(PATHS.LIST_CHANNEL_MESSAGES, {
        ...config,
        params: _params
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getConsent = async (token: string | undefined): Promise<ApiClient> => {
    try {
      const res = await axios.get(
        `${BASE_URL + "/" + PATHS.GET_CONSENT_DATA + "/" + token}`
      );
      return res.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getDeclinedConsent = async (
    token: string | undefined,
    consent_name: string | undefined
  ): Promise<ApiClient> => {
    try {
      const res = await axios.get(
        `${
          BASE_URL +
          "/" +
          PATHS.GET_DECLINE_CONSENT_DATA +
          "/" +
          token +
          "/" +
          consent_name
        }`
      );
      return res.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const postConsentData = async (
    consent_token: string | undefined,
    signer_name: string | undefined,
    signer_relationship: string | undefined,
    consent_name: string | undefined,
    pdffiledata: string | undefined,
    sign_type: string | undefined
  ) => {
    const requestData = {
      consentdata: {
        signer_name: signer_name,
        signer_relationship: signer_relationship,
        consent_token: consent_token,
        consent_name: consent_name,
        sign_type: sign_type
      },
      pdfdata: {
        pdffiledata: pdffiledata
      }
    };

    try {
      const res = await axios.post(
        `${BASE_URL + "/" + PATHS.POST_CONSENT_DATA}`,
        requestData
      );
      // console.log("POST request successful!", res.data);
      return res.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getValidateLoginMail = async (consent_token: string | undefined) => {
    try {
      await axios.get(
        `${
          BASE_URL + "/" + PATHS.GET_VALIDATE_LOGIN_MAIL + "/" + consent_token
        }`
      );
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getFhirMetaData = async (issQuery: string) => {
    // checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_FHIR_METADATA(issQuery), {
        ...config,
        headers: {
          "Content-Type": "application/fhir+json"
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const postEpicTokenResponse = async (body: OAuthTokenResponse) => {
    // checkTokenExpiry();
    try {
      const response = await axios.post(PATHS.POST_EPIC_TOKEN, body, {
        ...config,
        headers: {
          "Content-Type": "application/json",
          Authorization:
            "Basic EhCrU6+oJu3PDfi9qLKIBjlK3ciadZHzRH1cN67g2BSLH+r54Ulov+FQqQ5fkAo+850h0HGEtDyl9cpKJ2rmvQ=="
        }
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getConsentAfterLogin = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${BASE_URL + "/" + PATHS.GET_CONSENTS_AFTER_LOGIN}`,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const postConsentAfterLogin = async (
    consent_name: string | undefined,
    pdffiledata: string | undefined,
    signer_relationship: string | undefined,
    sign_type: string | undefined
  ) => {
    checkTokenExpiry();
    const reqBody = {
      consent_name: consent_name,
      byte_string: pdffiledata,
      signer_type: signer_relationship,
      sign_type: sign_type
    };
    try {
      const response = await axios.post(
        `${BASE_URL + "/" + PATHS.POST_CONSENT_AFTER_LOGIN}`,
        reqBody,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getDeclinedConsentAfterLogin = async (
    consent_name: string | undefined
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${
          BASE_URL +
          "/" +
          PATHS.GET_CONSENT_DECLINE_MAIL_AFTER_LOGIN +
          "/" +
          consent_name
        }`,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const uploadPatientSurvey = async (
    type: string,
    file: string,
    id: number,
    dateSelected: any
  ) => {
    const _params = {
      base64: file,
      type_key: type,
      date: dateSelected
    };
    try {
      const response = await axios.post(
        BASE_URL + "/" + PATHS.POST_PATIENT_SURVEY + "/" + id,
        _params,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getPatientSurveyList = async (patient_internal_id: string) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.PATIENT_SURVEY_LIST}/${patient_internal_id}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getUploadedSurvey = async (patient_internal_id: number) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.UPLOADED_PATIENT_SURVEY}/${patient_internal_id}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const postSurveyResponse = async (
    body: SurveyResponseDict,
    patient_internal_id: any,
    surveyId: any
  ) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.PATIENT_SURVEY}/${patient_internal_id}/${surveyId}`,
        body,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getReviewSurveyResponse = async (patient_internal_id: number) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.RESPONSE_PATIENT_SURVEY}/${patient_internal_id}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getPatientSurveyNotification = async (patient_internal_id: number) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.PATIENT_SURVEY_NOTIFICATION}/${patient_internal_id}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getVBCPrograms = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.VBC_LIST}`, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getPatientBillingData = async (
    provider_id: number | undefined,
    patient_internal_id: number | undefined,
    start_date: string | undefined,
    end_date: string | undefined
  ) => {
    try {
      const response = await axios.get(
        `${PATHS.GET_PATIENT_BILLING_DATA}/${provider_id}?patient_int_id=${patient_internal_id}&start_date=${start_date}&end_date=${end_date}`,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getDetails99454 = async (
    patient_internal_id: any,
    month: any,
    year: any
  ) => {
    try {
      const response = await axios.get(
        `${PATHS.GET_DETAILS_99454}/${patient_internal_id}/${month}/${year}`,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getAllPatientSurveyList = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.ALL_SURVEY_LIST}`, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const postAssignSurvey = async (body: AssignSurvey) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(PATHS.ASSIGN_SURVEY, body, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const putAllNotifications = async (params: {
    notification_obj: NotificationItem[];
    status: string;
  }) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        PATHS.MARK_ALL_NOTIFICATIONS(),
        params,
        config
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getDeclineDeviceAcknowledgement = async (patient_int_id: any) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        PATHS.GET_DECLINE_DEVICE_ACKNOWLEDGEMENT + "/" + patient_int_id,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getCategoricalData = async () => {
    checkTokenExpiry();
    try {
      const response = await axios.get(PATHS.GET_ALL_CATEGORICAL_DATA, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getSingleCategory = async (id: any) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.GET_SINGLE_CATEGORY + id}`, {
        ...config
      });
      // console.log("getSingleCategory", response?.data);
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getSelectedAnswer = async (id: any) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(`${PATHS.GET_SELECTED_ANSWER + id}`, {
        ...config
      });
      // console.log("getSelectedAnswer", response?.data);
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getKeyWordSearch = async (keyword: any) => {
    checkTokenExpiry();
    try {
      const response = await axios.get(
        `${PATHS.GET_SEARCHED_KEYWORD + keyword}`,
        {
          ...config
        }
      );
      // console.log("getSelectedAnswer", response?.data);
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getEditSurvey = async (
    patientInternalId?: string | number,
    surveyId?: string | number
  ) => {
    try {
      const response = await axios.get(
        `${PATHS.GET_EDIT_SURVEY}/${patientInternalId}/${surveyId}`,
        {
          ...config,
          params: {
            timeStamp: Date.now()
          }
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const closeEditSurvey = async (
    patientInternalId?: string | number,
    surveyId?: string | number
  ) => {
    // console.log("closeAPIparams", patientInternalId, surveyId);

    try {
      const response = await axios.get(
        `${PATHS.EDIT_SURVEY_CLOSE}/${patientInternalId}/${surveyId}`,
        {
          ...config,
          params: {
            timeStamp: Date.now()
          }
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const verifyVCToken = async (tokenData: TokenData) => {
    checkTokenExpiry();
    return axios
      .post(`${PATHS.VERIFY_VC_TOKEN}`, tokenData, {
        ...config
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return error.response.data;
      });
  };

  const postPamSurvey = async (
    patient_int_id: any,
    survey_id: any,
    body: any
  ) => {
    const final_body = {
      data_json: body
    };

    try {
      const response = await axios.post(
        `${PATHS.POST_PAM_SURVEY}/${patient_int_id}/${survey_id}`,
        final_body,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getPamSurveyReport = async (body: any) => {
    const filteredParams =
      body &&
      Object.fromEntries(
        Object?.entries(body).filter(([_, value]) => value !== "")
      );

    // console.log("filteredParams", filteredParams);
    try {
      const response = await axios.get(
        PATHS.GET_SURVEY_REPORT,

        {
          ...config,
          params: filteredParams
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const setThresholdData = async (bodyData: SetThresholdBody) => {
    checkTokenExpiry();
    try {
      const response = await axios.post(
        `${PATHS.POST_THRESHOLD_DATA}`,
        bodyData,
        config
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getThresholdData = async (patientInternalId?: string | number) => {
    try {
      const response = await axios.get(
        `${PATHS.GET_THRESHOLD_DATA}/${patientInternalId}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const setAlertAssisted = async (bodyData: SetAssestedBody) => {
    checkTokenExpiry();
    // console.log("bodyData", bodyData);
    try {
      const response = await axios.post(
        `${PATHS.POST_ASSISTED_DATA}`,
        bodyData,
        { ...config }
      );
      return response.data;
    } catch (error) {
      throw new Error(error as undefined);
    }
  };

  const getBillingsData = async () => {
    try {
      const response = await axios.get(`${PATHS.GET_BILLINGS_DATA}`, {
        ...config
      });
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  const getDisableContent = async (orgId: any) => {
    try {
      const response = await axios.get(
        `${PATHS.GET_DISABLE_COMPONENT}/${orgId}`,
        {
          ...config
        }
      );
      return response.data;
    } catch (error) {
      throw Error(error as undefined);
    }
  };

  return {
    setAlertAssisted,
    setThresholdData,
    getThresholdData,
    getKeyWordSearch,
    getSelectedAnswer,
    getSingleCategory,
    getCategoricalData,
    postPamSurvey,
    getDeclineDeviceAcknowledgement,
    getDetails99454,
    getPatientBillingData,
    getDeclinedConsentAfterLogin,
    postConsentAfterLogin,
    getConsentAfterLogin,
    getValidateLoginMail,
    postConsentData,
    uploadPatientSurvey,
    getDiagnosesList,
    getChargesList,
    getAllPatientsList,
    getAllPhysiciansList,
    getAllCaseManagersList,
    getAllNursesList,
    getAllMessagesList,
    getMessageHistory,
    getLoggedInUserData,
    getCareTeamList,
    getPatientProfile,
    getProviderProfile,
    getPatientRiskProfile,
    getPatientSymptoms,
    getAllBillsList,
    getCallRecords,
    getCallLogs,
    getAllProvidersList,
    getCallRecordNotes,
    postCallRecord,
    putCallRecord,
    addBill,
    editBill,
    getTotalMonthlyCallTime,
    deleteBill,
    addProviders,
    getLabData,
    getFluidMetricsData,
    getActiveMedicationList,
    getRemoteVitalsData,
    getDisclaimer,
    getPatientTerms,
    getProviderTerms,
    getPatientsEULA,
    getProvidersEULA,
    getPrivacyPolicy,
    getMedicationHistoryList,
    getDiscontinuedMedicationList,
    getMedicalReasonsList,
    getMedicineAutocomplete,
    getMedicineUnits,
    getMedicineSig,
    getMedicineDuration,
    getMedicineInfoFrom,
    checkMedicineDuplicate,
    saveMedication,
    discontinueMedication,
    getSurveyLinks,
    scheduleAppointment,
    resetPassword,
    getAppointmentList,
    removeAppointment,
    addFallsFormData,
    addNauseaFormData,
    addFeverFormData,
    addShortnessOfBreathFormData,
    addLegSwellingFormData,
    addWeightChangeFormData,
    addFatigueFormData,
    addChestPainFormData,
    addAchesAndPainsFormData,
    addLightheadednessFormData,
    addUlcersFormData,
    addAppetiteImpairmentFormData,
    addMoodImpairmentFormData,
    addUrinaryFormData,
    addDialysisFormData,
    addVitalSignsFormData,
    getUserChannels,
    updatePatientParticipation,
    getChannel,
    createMeeting,
    endMeeting,
    endTwilioMeeting,
    startTwilioMeeting,
    joinMeeting,
    getTwilioToken,
    getTwilioCallers,
    activeMeeting,
    getAllChats,
    deleteMedication,
    getMedicationDiscontinuedReasons,
    deleteCallRecord,
    getUserNetworkList,
    getNotifications,
    putNotifications,
    listChannelMessages,
    getConsent,
    getDeclinedConsent,
    getFhirMetaData,
    postEpicTokenResponse,
    getPatientSurveyList,
    postSurveyResponse,
    getUploadedSurvey,
    getReviewSurveyResponse,
    getPatientSurveyNotification,
    getVBCPrograms,
    getAllPatientSurveyList,
    postAssignSurvey,
    putAllNotifications,
    getEditSurvey,
    closeEditSurvey,
    verifyVCToken,
    getPamSurveyReport,
    getBillingsData,
    getDisableContent
  };
};
